import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    margin: 104px 0 120px;
    max-width: 1092px;
    @media (${mediaSm}) {
        margin: 60px 0;
    }
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 24px;
        margin: 40px 0 60px;
    }
`;
