import React, { FC } from 'react';
import { useCourse } from 'contexts/course.context';
import { Back2ListBtn } from '../../../news-item/styled';
import { Icon } from 'kl-b2c-ui-kit';
import {
    ButtonsBlock,
    Content,
    Description,
    DescriptionBlock,
    LogoName,
    ResultList,
    TitleListItem,
    ValueListItem,
} from './styled';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/button';

interface IProps {
    onClick: () => void;
}

export const Header: FC<IProps> = ({ onClick }) => {
    const { t } = useTranslation(['pages/courses', 'common/shared']);
    const { course } = useCourse();

    return (
        <Content imageUrl={course?.image?.fileLink ?? ''}>
            <Back2ListBtn to={'/courses/'}>
                <Icon name={'ArrowLeft'} color={'teal'} size={20} />
                {t('pages/courses:back2ListBtnCaption')}
            </Back2ListBtn>
            <DescriptionBlock>
                <LogoName>Kaspersky Academy</LogoName>
                <Description>{course?.name ?? ''}</Description>
                <ButtonsBlock>
                    <PrimaryButton onClick={onClick}>
                        {t('pages/courses:request-button')}
                    </PrimaryButton>
                    <ResultList>
                        <li>
                            <TitleListItem>
                                {t('pages/courses:duration')}
                            </TitleListItem>
                            <ValueListItem>{course?.duration}</ValueListItem>
                        </li>
                        <li>
                            <TitleListItem>
                                {t('pages/courses:result')}
                            </TitleListItem>
                            <ValueListItem>{course?.result}</ValueListItem>
                        </li>
                        <li>
                            <TitleListItem>
                                {t('pages/courses:forWhom')}
                            </TitleListItem>
                            <ValueListItem>
                                {course?.whoIsNecessary}
                            </ValueListItem>
                        </li>
                        {course?.languages && course.languages.length > 0 && (
                            <li>
                                <TitleListItem>
                                    {t('pages/courses:languages')}
                                </TitleListItem>
                                <ValueListItem>
                                    {course?.languages
                                        .map((lang) =>
                                            t(`common/shared:lang-${lang}`)
                                        )
                                        .join(', ')}
                                </ValueListItem>
                            </li>
                        )}
                    </ResultList>
                </ButtonsBlock>
            </DescriptionBlock>
        </Content>
    );
};
