import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    Content,
    DisclaimerExperts,
    ExpertCard,
    ExpertName,
    ExpertPosition,
    ExpertWrap,
    Title,
    SliderStyler,
} from './styled';
import Slider, { Settings } from 'react-slick';
import { Lang } from 'types';
import { langMapper } from 'utils';
import {
    DotsContainer,
    DotsList,
} from 'containers/pages/for-businesses/components/reviews/styled';

export const Experts = () => {
    const { t, i18n } = useTranslation('pages/courses');
    const { course } = useCourse();

    const CAROUSEL_SETTINGS: Settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        centerMode: false,
        infinite: false,
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList>{dots}</DotsList>
            </DotsContainer>
        ),
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Content>
            <Title>{t('experts')}</Title>
            <SliderStyler>
                <Slider {...CAROUSEL_SETTINGS}>
                    {course?.speakers.map((speaker) => (
                        <ExpertCard key={speaker.id} className={'card-item'}>
                            <ExpertWrap image={speaker.image?.fileLink ?? ''} />
                            <ExpertName>
                                {speaker.firstName} {speaker.lastName}
                            </ExpertName>
                            <ExpertPosition>{speaker.position}</ExpertPosition>
                        </ExpertCard>
                    ))}
                </Slider>
            </SliderStyler>

            {langMapper[i18n.language] === Lang.Ru && (
                <DisclaimerExperts>{t('disclaimer')}</DisclaimerExperts>
            )}
        </Content>
    );
};
