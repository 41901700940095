import { PrimaryButton } from 'components/button';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h3`
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    text-align: center;
    padding-bottom: 30px;
`;

export const Question = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    padding-bottom: 30px;
`;

export const AnswersBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 11px;
    padding-bottom: 30px;
`;

export const Answer = styled.div`
    background-image: radial-gradient(
            circle at 100% 100%,
            transparent 11px,
            #8ca3d1 0,
            #8ca3d1 12px,
            transparent 0
        ),
        linear-gradient(90deg, #8ca3d1, #18e1e3),
        radial-gradient(
            circle at 0 100%,
            transparent 11px,
            #18e1e3 0,
            #18e1e3 12px,
            transparent 0
        ),
        linear-gradient(180deg, #18e1e3, #18e1e3),
        radial-gradient(
            circle at 0 0,
            transparent 11px,
            #18e1e3 0,
            #18e1e3 12px,
            transparent 0
        ),
        linear-gradient(270deg, #18e1e3, #8ca3d1),
        radial-gradient(
            circle at 100% 0,
            transparent 11px,
            #8ca3d1 0,
            #8ca3d1 12px,
            transparent 0
        ),
        linear-gradient(0deg, #8ca3d1, #8ca3d1);
    background-position: 0 0, top, 100% 0, 100%, 100% 100%, bottom, 0 100%, 0;
    background-repeat: no-repeat;
    background-size: 12px 12px, calc(100% - 24px) 1px, 12px 12px,
        1px calc(100% - 24px);
    border-radius: 10px;
    display: block;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    padding: 20px;
    width: 100%;
    cursor: pointer;
`;

export const ButtonBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BackButton = styled(PrimaryButton)``;
