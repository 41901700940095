import styled from 'styled-components';
import { colorPrimary, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    margin-bottom: 40px;
    text-align: left;
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    @media (${mediaSm}) {
        margin-bottom: 20px;
    }
    @media (${mediaXs}) {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 43px;
    }
`;

export const PricesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;

export const Price = styled.div``;

export const PriceTitle = styled.h3`
    color: ${colorPrimary};
    font-size: 70px;
    line-height: 85px;
    white-space: nowrap;
`;
