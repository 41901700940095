import { CreateThemeInput } from 'kl-b2c-ui-kit';

export const light: CreateThemeInput = {
    palette: {
        titleHighlight: '#96dfa5',
        headerBackground: '#fff',
        background: '#fff',
        menuBackground: '#f8f8f8',
        black: '#1d1d1b',
        teal: '#3DE8CA',
    },
    shadows: {
        dropdown: '0 8px 16px 0px rgba(0, 0, 0, 0.2)',
        card: {
            hover: '-5px 5px 18px rgba(35, 209, 174, 0.3)',
        },
    },
    breakpoints: {
        values: {
            xs: 639,
            sm: 1023,
            md: 1279,
        },
    },
    media: {
        xs: 'max-width: 767px',
        sm: 'max-width: 1023px',
        md: 'max-width: 1279px',
        lg: 'max-width: 1600px',
        mobile: 'min-width: 767px',
        tablet: 'min-width: 1024px',
        bigTablet: 'min-width: 1279px',
        superBigTablet: 'min-width: 1400px',
    },
    commonTypography: {
        fontFamily: 'Kaspersky Sans Display',
    },
    typography: {
        checkbox: {
            lineHeight: 16,
            fontSize: 12,
        },
        textField: {
            fontSize: 14,
            lineHeight: 18,
        },
        button: {
            letterSpacing: 0,
            fontSize: 16,
        },
    },
    shape: {
        headerHeight: '64px',
        borderRadius: {
            button: 3,
            checkbox: 4,
        },
    },
    animation: {
        base: 'all 0.5s ease',
    },
};
