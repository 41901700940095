import {
    ApiService,
    ConfigApi,
    SpeakerApi,
    FormsApi,
    SettingsService,
    StorageService,
    NewsApi,
    EventApi,
    CourseApi,
    ProfessionApi,
} from 'services';
import {
    IConfigApi,
    IApiService,
    ISettingsService,
    IStorageService,
    ISpeakerService,
    IFormsService,
    INewsService,
    IEventService,
    ICourseService,
    IProfessionService,
} from 'types';
import { IOrganizationsService, IReviewsService } from 'types/api-service';
import { OrganizationApi, ReviewsApi } from './api';

export const getApiService = (
    tokenStorageService: IStorageService
): IApiService => new ApiService(tokenStorageService);
export const getConfigApiService = (apiService: IApiService): IConfigApi =>
    new ConfigApi(apiService);
export const getSettingsService = (): ISettingsService => new SettingsService();
export const getStorageService = (): IStorageService => new StorageService();
export const getSpeakerApiService = (): ISpeakerService =>
    new SpeakerApi(getStorageService());
export const getReviewsApiService = (): IReviewsService =>
    new ReviewsApi(getStorageService());
export const getEventApiService = (): IEventService =>
    new EventApi(getStorageService());
export const getFormsApiService = (): IFormsService =>
    new FormsApi(getStorageService());
export const getNewsApiService = (): INewsService =>
    new NewsApi(getStorageService());
export const getCourseApiService = (): ICourseService =>
    new CourseApi(getStorageService());
export const getProfessionApiService = (): IProfessionService =>
    new ProfessionApi(getStorageService());
export const getOrganizationsApiService = (): IOrganizationsService =>
    new OrganizationApi(getStorageService());
