import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
    EventType,
    ProfessionDetail,
    ProfessionGroupType,
    ProfessionTable,
} from 'types';
import {
    Announcement,
    Content,
    Description,
    DurationText,
    ImageBlock,
    Tag,
    TagBlock,
    Title,
} from './styled';
import DOMPurify from 'dompurify';
import { ButtonBlock, ButtonClick, ButtonLink } from '../styled';

interface IProps {
    profession: ProfessionTable;
    secondary?: boolean;
    openRegister?: (profession: ProfessionDetail) => void;
}

export const ProfessionItem: FC<IProps> = ({
    profession,
    secondary,
    openRegister,
}) => {
    const { t } = useTranslation('common/shared');

    const getRedirectLink = (profession: ProfessionTable): ReactElement => {
        switch (profession.groupType) {
            case ProfessionGroupType.Alliance: {
                return (
                    <ButtonLink to={'/academy-alliance/'}>
                        {t('know-more')}
                    </ButtonLink>
                );
            }
            case ProfessionGroupType.Safeboard: {
                return (
                    <ButtonLink
                        to={'https://safeboard.kaspersky.ru/'}
                        target={'_blank'}>
                        {t('know-more')}
                    </ButtonLink>
                );
            }
            default: {
                return (
                    <ButtonLink
                        to={
                            !profession.isExistCourse && profession.link
                                ? profession.link
                                : `/profession/${profession.groupName}`
                        }>
                        {t('know-more')}
                    </ButtonLink>
                );
            }
        }
    };

    const getRedirectString = (): string => {
        switch (profession.groupType) {
            case ProfessionGroupType.Alliance: {
                return '/academy-alliance/';
            }
            case ProfessionGroupType.Safeboard: {
                return 'https://safeboard.kaspersky.ru/';
            }
            default: {
                return profession.link ?? `/profession/${profession.groupName}`;
            }
        }
    };

    return (
        <Content>
            <Description>
                {profession.groupType === ProfessionGroupType.None && (
                    <TagBlock>
                        {profession.isFree && (
                            <Tag isGreen>{t('common/shared:free')}</Tag>
                        )}
                        {profession.studyForms.map((r) => (
                            <Tag
                                white={r === EventType.Offline}
                                key={`study-form-key-${r}`}>
                                {t(`common/shared:studyForm-${r}`)}
                            </Tag>
                        ))}
                        {profession.isExistCourse && (
                            <Tag>{t('common/shared:profession')}</Tag>
                        )}
                    </TagBlock>
                )}

                <Title
                    to={getRedirectString()}
                    noPadding={
                        profession.groupType !== ProfessionGroupType.None
                    }>
                    {profession.name}
                </Title>
                <Announcement
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            profession.announcementDescription
                        ),
                    }}
                />
                {profession.groupType === ProfessionGroupType.None && (
                    <DurationText>{profession?.duration ?? ''}</DurationText>
                )}
                <ButtonBlock>
                    {profession.groupType === ProfessionGroupType.None &&
                        !secondary &&
                        profession.baseDescription && (
                            <ButtonClick
                                onClick={() =>
                                    openRegister &&
                                    openRegister(profession as ProfessionDetail)
                                }>
                                {t('buy')}
                            </ButtonClick>
                        )}

                    {getRedirectLink(profession)}
                </ButtonBlock>
            </Description>
            <ImageBlock image={profession.additionalImage?.fileLink ?? ''} />
        </Content>
    );
};
