import { Disclaimer } from 'containers/pages/about/components/promo-section/styled';
import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    text-align: left;
    margin-bottom: 60px;
    @media (${mediaXs}) {
        font-size: 36px;
        line-height: 43px;
    }
`;

export const ExpertCard = styled.div`
    text-align: center;
    width: 100%;
`;

export const ExpertWrap = styled.div<{ image: string }>`
    background-image: url(${({ image }) => image});
    width: 322px;
    height: 322px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 0;
    mask: url(/assets/images/mask-speaker.png) center / contain;
    mask-repeat: no-repeat;
    margin: 0 auto;
`;

export const ExpertName = styled.h3`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 10px;
`;

export const ExpertPosition = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 26px;
`;

export const DisclaimerExperts = styled(Disclaimer)`
    position: inherit;
    padding-top: 30px;
`;

export const SliderStyler = styled.div`
    .slick-track {
        margin-left: 0;
    }
`;
