import React from 'react';
import { useCourse } from 'contexts/course.context';
import { Content } from './styled';
import DOMPurify from 'dompurify';

export const Description = () => {
    const { course } = useCourse();
    return (
        <Content
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course?.description ?? ''),
            }}
        />
    );
};
