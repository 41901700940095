import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import { Content, ListContent, ListItem, Title } from './styled';

export const Teasers = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <Content>
            <Title>{t('teaserTitle')}</Title>
            <ListContent>
                {course?.teasers.map((teaser) => (
                    <ListItem key={teaser.id}>{teaser.name}</ListItem>
                ))}
            </ListContent>
        </Content>
    );
};
