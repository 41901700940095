import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 60px;
    }
    @media (${mediaXs}) {
        margin-bottom: 30px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    margin-bottom: 38px;
    text-align: left;
    @media (${mediaXs}) {
        font-size: 36px;
        line-height: 43px;
    }
`;

export const ListContent = styled.ul`
    grid-gap: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    @media (${mediaSm}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (${mediaXs}) {
        grid-gap: 16px;
        grid-template-columns: 1fr;
    }
`;

export const ListItem = styled.li`
    grid-column-gap: 20px;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
    display: grid;
    grid-template-columns: 74px 1fr;
    min-height: 186px;
    padding: 22px 20px;
`;

export const ListItemImage = styled.div`
    img {
        height: auto;
        max-width: 100%;
        display: block;
        width: 64px;
    }
`;

export const ListItemText = styled.div`
    h3 {
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        margin-bottom: 10px;
    }
    p {
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
    }
`;
