import styled from 'styled-components';
import { Button } from 'kl-b2c-ui-kit';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    margin-bottom: 60px;
    text-align: left;
    @media (${mediaXs}) {
        font-size: 36px;
        line-height: 43px;
    }
`;

export const BottomBlock = styled.div`
    align-items: center;
    background: #f8f8f8;
    border-radius: 0 0 10px 10px;
    display: flex;
    padding: 40px;
    @media (${mediaSm}) {
        grid-row-gap: 30px;
        display: grid;
        grid-template-columns: 1fr;
    }
    @media (${mediaXs}) {
        padding: 16px;
    }
`;

export const BottomText = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    margin-right: 52px;
    max-width: 440px;
    @media (${mediaSm}) {
        grid-row-gap: 30px;
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const ButtonsBlock = styled.div`
    align-items: center;
    column-gap: 20px;
    display: flex;
    @media (${mediaXs}) {
        grid-row-gap: 20px;
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const ButtonBig = styled(Button)<{ border?: boolean }>`
    min-width: 160px;
    padding: 12px 20px;
    border: ${(props) =>
        props.border
            ? '2px solid ${({ theme }) => theme.palette.teal};'
            : 'none'};
`;
