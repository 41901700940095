import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;

    transform-origin: left bottom;
    transform: skewY(3deg);
    border-radius: 0 0 40px 40px;
    margin-bottom: -35px;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 670px;
    margin: 0 auto;
    align-items: flex-start;
    gap: 40px;
`;

export const Title = styled.h2`
    font-weight: 400;
    font-size: 81px;
    line-height: 81px;

    @media (${mediaSm}) {
        font-size: 55px;
        line-height: 55px;
    }

    @media (${mediaXs}) {
        font-size: 32px;
        line-height: 32px;
    }
`;

export const Description = styled.p`
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;

    @media (${mediaSm}) {
        font-size: 19px;
        line-height: 24px;
        max-width: 520px;
    }
`;
