import styled from 'styled-components';
import { colorWhite, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div<{ imageUrl: string }>`
    background-color: #f6fbfd;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 105px);
    justify-content: space-between;
    padding: 82px;
    background-image: url(${(props) => props.imageUrl});
    &:after {
        background: radial-gradient(
            50% 50% at 50% 50%,
            #43b6f7 45.31%,
            rgba(223, 247, 255, 0) 100%
        );
        top: 460px;
        content: '';
        filter: blur(70px);
        height: 683px;
        position: absolute;
        right: -50px;
        width: 683px;
        z-index: -1;
    }
    @media (${mediaSm}) {
        padding: 40px 20px;
    }
    @media (${mediaXs}) {
        border-radius: 32px;
        min-height: calc(100vh - 73px);
        padding: 40px 20px;
    }
`;

export const DescriptionBlock = styled.div``;

export const LogoName = styled.p`
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    color: ${colorWhite};
    @media (${mediaSm}) {
        font-size: 24px;
        line-height: 32px;
    }
`;
export const Description = styled.h1`
    color: ${colorWhite};
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 77px;
    margin-bottom: 40px;
    max-width: 623px;
    overflow-y: hidden;
    @media (${mediaSm}) {
        font-size: 42px;
        line-height: 56px;
    }
    @media (${mediaXs}) {
        font-size: 36px;
        line-height: 42px;
    }
`;

export const ButtonsBlock = styled.div`
    align-items: flex-start;
    display: flex;
    @media (${mediaSm}) {
        display: block;
    }
`;

export const ResultList = styled.ul`
    align-items: flex-start;
    column-gap: 40px;
    display: flex;
    list-style: none;
    margin: 0 0 0 45px;
    padding: 0;
    @media (${mediaSm}) {
        column-gap: 40px;
        margin-left: 0;
        margin-top: 20px;
    }
    @media (${mediaXs}) {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    li {
        &:nth-child(4) {
            white-space: nowrap;
        }
    }
`;

export const TitleListItem = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: ${colorWhite};
    @media (${mediaXs}) {
        font-size: 14px;
        line-height: 18px;
    }
`;

export const ValueListItem = styled.p`
    color: ${({ theme }) => theme.palette.green};
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 22px;
    }
`;
