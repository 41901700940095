import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    Content,
    ListContent,
    ListItem,
    ListItemImage,
    ListItemText,
    Title,
} from './styled';
import DOMPurify from 'dompurify';

export const Results = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <Content>
            <Title>{t('courseResults')}</Title>
            <ListContent>
                {course?.results.map((result) => (
                    <ListItem key={result.id}>
                        {result.image && (
                            <ListItemImage>
                                <img src={result.image.fileLink} />
                            </ListItemImage>
                        )}
                        <ListItemText>
                            <h3>{result.name}</h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        result.description ?? ''
                                    ),
                                }}
                            />
                        </ListItemText>
                    </ListItem>
                ))}
            </ListContent>
        </Content>
    );
};
