import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    text-align: left;
    margin-bottom: 40px;
    @media (${mediaXs}) {
        margin-bottom: 30px;
    }
`;

export const ListContent = styled.ul`
    grid-gap: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (${mediaXs}) {
        grid-gap: 16px;
        grid-template-columns: 1fr;
    }
`;

export const ListItem = styled.li`
    background: linear-gradient(144.23deg, #60d8a8, #43b6f7 79.06%), #60d8a8;
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.white};
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    min-height: 154px;
    padding: 30px 120px 40px 40px;
    position: relative;

    &:after {
        background-image: url(/assets/images/teaser-item.svg);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        content: '';
        height: 148px;
        position: absolute;
        right: 0;
        width: 139px;
    }
`;
