import React, {
    Context,
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getCourseApiService } from 'services/api.factory';
import {
    CourseDetail,
    CourseRegistration,
    CourseTable,
    GetCourse,
    GetCourses,
    Paging,
} from 'types';

interface CourseContext {
    courses: Paging<CourseTable>;
    reservedCourses: CourseTable[];
    course?: CourseDetail;
    loading: boolean;
    isError: boolean;
    errorText: string;
    getList: (params: GetCourses) => Promise<void>;
    appendList: (params: GetCourses) => Promise<void>;
    getDetailed: (params: GetCourse) => Promise<void>;
    register: (data: CourseRegistration) => Promise<void | unknown>;
}

const CourseContext: Context<CourseContext> = createContext<CourseContext>(
    {} as CourseContext
);

const CourseProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation('pages/courses');
    const [courses, setCourses] = useState<Paging<CourseTable>>({
        items: [],
        count: 0,
    });
    const [reservedCourses, setReservedCourses] = useState<CourseTable[]>([]);
    const [course, setCourse] = useState<CourseDetail>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState('');
    const api = getCourseApiService();

    const getList = (params: GetCourses): Promise<void> => {
        setLoading(true);
        return api
            .getList(params)
            .then(
                (data) => {
                    setCourses(
                        data?.data ?? {
                            items: [],
                            count: 0,
                        }
                    );

                    if (
                        reservedCourses.length === 0 &&
                        data?.data?.items !== null &&
                        data?.data?.items?.length !== 0
                    )
                        setReservedCourses([...data.data.items]);
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const appendList = (params: GetCourses): Promise<void> => {
        setLoading(true);
        return api
            .getList(params)
            .then(
                (data) => {
                    setCourses({
                        ...courses,
                        items: [...courses.items, ...data.data.items],
                    });
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const getDetailed = (params: GetCourse): Promise<void> => {
        setLoading(true);
        setIsError(false);
        return api
            .getDetailed(params)
            .then(
                (data) => {
                    setCourse(data?.data ?? undefined);
                },
                (e) => {
                    setIsError(true);
                    throw e;
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const register = (data: CourseRegistration): Promise<void | unknown> =>
        api.register(data).catch(() => new Promise((_, reject) => reject()));

    const memoValue = useMemo(
        () => ({
            courses,
            course,
            loading,
            isError,
            errorText,
            reservedCourses,
            getList,
            getDetailed,
            appendList,
            register,
        }),
        [courses, course, loading, reservedCourses, register]
    );

    return (
        <CourseContext.Provider value={memoValue}>
            {children}
        </CourseContext.Provider>
    );
};

export const useCourse = () => useContext(CourseContext);
export default CourseProvider;
