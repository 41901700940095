import React, { FC } from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    BottomBlock,
    BottomText,
    ButtonBig,
    ButtonsBlock,
    Content,
    Title,
} from './styled';
import { Tab } from 'components';
import DOMPurify from 'dompurify';
import { langMapper } from 'utils/lang-convert';
import { Lang } from 'types/enums';

interface IProps {
    onClick: () => void;
}

export const Program: FC<IProps> = () => {
    const { t, i18n } = useTranslation('pages/courses');
    const { course } = useCourse();
    const langDomain = langMapper[i18n.language];

    const tabs = {
        tabs: [
            {
                key: 'topic',
                isActive: true,
                body: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(course?.topic ?? ''),
                        }}
                    />
                ),
                title: (
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('topic')),
                        }}
                    />
                ),
            },
            {
                key: 'practicePart',
                body: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                course?.practicePart ?? ''
                            ),
                        }}
                    />
                ),
                title: (
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('practicePart')),
                        }}
                    />
                ),
            },
        ],
        styles: {
            backgroundColor:
                'linear-gradient(144.28deg, #60d8a8 22.41%, #43b6f7 117.57%), #23d1ae;',
            textColor: 'white',
            rootStyles: `
                    border-radius: 10px 10px 0px 0px;
                    padding: 0;
                    margin: 0;
            `,
            bodyStyles: `padding: 20px; 
            ul {
                padding: 0 0 0 20px;
                margin: 0;
                display: block !important;
            }`,
            menuStyles: `
                li {
                    font-size: 20px;
                }
            `,
        },
    };

    return (
        <Content>
            <Title>{t('program')}</Title>
            <Tab {...tabs} />
            {(course?.isAwareness || course?.additionalProgram?.fileLink) && (
                <BottomBlock>
                    <BottomText>{t('programText')}</BottomText>
                    <ButtonsBlock>
                        <ButtonBig
                            type={'button'}
                            color={'secondary'}
                            border
                            onClick={() => {
                                if (course?.isAwareness) {
                                    return window.open(
                                        langDomain === Lang.Ru
                                            ? 'https://www.kaspersky.ru/enterprise-security'
                                            : 'https://www.kaspersky.com/enterprise-security',
                                        '_blank',
                                        'noopener,noreferrer'
                                    );
                                } else if (course?.additionalProgram?.fileLink)
                                    return window.open(
                                        course?.additionalProgram?.fileLink,
                                        '_blank',
                                        'noopener,noreferrer'
                                    );
                            }}>
                            {t('learnMore')}
                        </ButtonBig>
                    </ButtonsBlock>
                </BottomBlock>
            )}
        </Content>
    );
};
