import styled, { css } from 'styled-components';

export const FooterRoot = styled.footer<{ isCorporatePage: boolean }>`
    display: flex;
    flex-direction: column;

    align-items: center;
    overflow: hidden;

    ${({ isCorporatePage }) =>
        isCorporatePage &&
        css`
            padding: 90px 0 0 0;
        `};
`;
