import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import { Content, Price, PricesContainer, PriceTitle, Title } from './styled';
import { CoursePrice } from 'types';
import DOMPurify from 'dompurify';
import { Typography } from 'kl-b2c-ui-kit';

export const Prices = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <Content>
            <Title>{t('prices')}</Title>
            <PricesContainer>
                {course?.prices.map((price: CoursePrice) => (
                    <Price key={price.id}>
                        <PriceTitle>{price.name}</PriceTitle>
                        <Typography variant={'p2'} fontWeight={'regular'}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        price.description
                                    ),
                                }}
                            />
                        </Typography>
                    </Price>
                ))}
            </PricesContainer>
        </Content>
    );
};
