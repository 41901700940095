import { MenuElement } from 'types';

export const headerMenuItemsRu: MenuElement[] = [
    {
        link: '/courses/',
        i18nKey: 'corporateTraining',
        dataAtSelector: 'headerCorporateTrainingLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/for-businesses/',
        i18nKey: 'forBusinesses',
        dataAtSelector: 'headerForBusinessesLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/academy-alliance/',
        i18nKey: 'academyAlliance',
        dataAtSelector: 'headerAllianceLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/for-governments/',
        i18nKey: 'forGovernments',
        dataAtSelector: 'headerForGovernmentsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/about/',
        i18nKey: 'about',
        dataAtSelector: 'headerAboutLink',
        withArrow: false,
        isExternal: false,
    },
];

export const headerSubMenuItemsRu: MenuElement[] = [
    {
        i18nKey: 'kidsCyberResilience',
        link: '/kids-cyber-resilience/',
        dataAtSelector: 'kidsCyberResilience',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/news/',
        i18nKey: 'news',
        dataAtSelector: 'headerNewsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/events/',
        i18nKey: 'events',
        dataAtSelector: 'headerEventsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'comunities',
        link: 'https://cybermates.kaspersky.com/',
        dataAtSelector: 'headerCybermatesExternalLink',
        withArrow: true,
        isExternal: true,
    },
    {
        i18nKey: 'secureitcup',
        link: 'https://secureitcup.kaspersky.com/ru/',
        dataAtSelector: 'headerSecureitcupExternalLink',
        withArrow: true,
        isExternal: true,
    },
    {
        i18nKey: 'internship',
        link: 'https://safeboard.kaspersky.ru/',
        dataAtSelector: 'headerSafeboardExternalLink',
        withArrow: true,
        isExternal: true,
    },
];

export const headerSubMenuItemsEn: MenuElement[] = [
    {
        i18nKey: 'kidsCyberResilience',
        link: '/kids-cyber-resilience/',
        dataAtSelector: 'kidsCyberResilience',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/news/',
        i18nKey: 'news',
        dataAtSelector: 'headerNewsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/events/',
        i18nKey: 'events',
        dataAtSelector: 'headerEventsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'comunities',
        link: 'https://cybermates.kaspersky.com/',
        dataAtSelector: 'headerCybermatesExternalLink',
        withArrow: true,
        isExternal: true,
    },
    {
        i18nKey: 'internship',
        link: 'https://safeboard.kaspersky.ru/',
        dataAtSelector: 'headerSafeboardExternalLink',
        withArrow: true,
        isExternal: true,
    },
    {
        i18nKey: 'secureitcup',
        link: 'https://secureitcup.kaspersky.com/',
        dataAtSelector: 'headerSecureitcupExternalLink',
        withArrow: true,
        isExternal: true,
    },
];
