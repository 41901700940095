import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    Content,
    ForBlock,
    ForText,
    List,
    ListItem,
    Title,
    ToolTipBlock,
} from './styled';
import { Icon } from 'kl-b2c-ui-kit';
import DOMPurify from 'dompurify';

export const ForWhom = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <Content>
            <Title>{t('forWhom')}</Title>
            <List>
                {course?.targetAudiences.map((item) => (
                    <ListItem key={item.id}>
                        <ForBlock>
                            <Icon color={'primary'} name={'Check'} size={32} />
                            <ForText>{item.name}</ForText>
                            {item.description && (
                                <ToolTipBlock>
                                    <Icon
                                        color={'primary'}
                                        name={'Info'}
                                        size={24}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                item.description
                                            ),
                                        }}
                                    />
                                </ToolTipBlock>
                            )}
                        </ForBlock>
                    </ListItem>
                ))}
            </List>
        </Content>
    );
};
