import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    margin-bottom: 80px;
    text-align: left;
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    @media (${mediaSm}) {
        margin-bottom: 60px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 43px;
    }
`;

export const List = styled.ul`
    grid-column-gap: 120px;
    grid-row-gap: 50px;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (${mediaMd}) {
        grid-column-gap: 60px;
        grid-row-gap: 40px;
    }
    @media (${mediaSm}) {
        grid-column-gap: 30px;
        grid-row-gap: 40px;
        grid-template-columns: 1fr 1fr;
    }
    @media (${mediaXs}) {
        grid-row-gap: 30px;
        grid-template-columns: 1fr;
    }
`;

export const ListItem = styled.li`
    align-items: flex-start;
    display: flex;
    &:hover p {
        visibility: visible;
        opacity: 1;
    }
`;

export const ForBlock = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 30px;
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
    cursor: pointer;
    display: flex;
    padding: 16px 22px;
    width: 100%;
`;
export const ForText = styled.h3`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 29px;
    margin-right: 7px;
    padding-left: 10px;
`;

export const ToolTipBlock = styled.div`
    flex-shrink: 0;
    margin-left: auto;
    position: relative;
    p {
        background: ${({ theme }) => theme.palette.white};
        border-radius: 20px;
        bottom: 30px;
        box-shadow: 45px 55px 28px rgba(24, 225, 227, 0.01),
            25px 31px 24px rgba(24, 225, 227, 0.05),
            11px 14px 18px rgba(24, 225, 227, 0.09),
            3px 3px 10px rgba(24, 225, 227, 0.1), 0 0 0 rgba(24, 225, 227, 0.1);
        left: 50%;
        opacity: 0;
        padding: 20px 15px;
        position: absolute;
        transform: translateX(-50%);
        transition: all 0.5s ease;
        visibility: hidden;
        width: 242px;
        @media (${mediaXs}) {
            left: auto;
            right: -30px;
            transform: none;
        }
        &:after {
            background: ${({ theme }) => theme.palette.white};
            border-radius: 3px;
            bottom: -2px;
            content: '';
            height: 18.88px;
            left: 50%;
            position: absolute;
            transform: rotate(-45deg) translateX(-50%);
            width: 18.88px;
            @media (${mediaXs}) {
                left: auto;
                right: 20px;
                transform: rotate(-45deg) translateX(-50%);
            }
        }
    }
`;
